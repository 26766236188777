/* eslint-disable no-undef */
import $ from 'jquery';
import qs from 'qs';
import user from '../plugins/user'

let token = user.user ? user.user.t : null;

var blockUI = true;
export default {
    expireHandler: [],

    addOnExpireHandler(callback) {
        for (var k in this.expireHandler) {
            if (this.expireHandler[k] == callback) {
                return;
            }
        }
        this.expireHandler.push(callback);
    },

    removeOnExpireHandler(callback) {
        this.expireHandler.remove(callback);
    },

    raiseOnExpire() {
        this.expireHandler.forEach(handler => {
            handler(this);
        });
    },

    setBlockUI(val) {
        blockUI = val;
    },

    setToken(t) {
        token = t;
    },
    getUrl(url) {
        if (token) {
            if (url.indexOf('?') > 0) {
                url += '&t=' + encodeURIComponent(token);
            } else {
                url += '?t=' + encodeURIComponent(token);
            }
        }
        return config.host + url;
    },
    handle(p) {
        return new Promise((resolve, reject) => {
            if (window.blockUI && blockUI) {
                window.blockUI();
            }
            p.then(res => {
                if (res.Success) {
                    if (res.Token) {
                        var t = res.Token;
                        user.setToken(t);
                        this.setToken(t);
                    }
                    resolve(res.Result);
                } else {
                    reject(res)
                }
            })
                .catch((err) => {
                    console.log(err);
                    if (err.status == 410) {
                        this.raiseOnExpire();
                        return;
                    }
                    reject(err)
                }).always(function () {
                    window.unblockUI();
                });
        });
    },
    get(url, data) {
        if (!data) {
            return this.handle($.get(this.getUrl(url)));
        } else {
            return this.handle($.get(this.getUrl(url) + qs.stringify(url)));
        }
    },
    post(url, data) {
        return this.handle($.ajax({
            type: "POST",
            url: this.getUrl(url),
            data: JSON.stringify(data),
            contentType: "application/json"
        }));
    },
    multipartPost(url, data) {
        url = this.getUrl(url);
        var fdata = new FormData();
        for (var key in data) {
            fdata.append(key, data[key]);
        }
        return $.ajax({
            type: "POST",
            url: url,
            contentType: false,
            processData: false,
            data: fdata
        });
    },
    put(url, data) {
        return this.handle($.ajax({
            type: "PUT",
            url: this.getUrl(url),
            data: JSON.stringify(data),
            contentType: "application/json"
        }));
    },
    del(url) {
        return this.handle($.ajax({
            type: "DELETE",
            url: this.getUrl(url),
        }));
    },
    hasToken() {
        return token != null && token != undefined && token != "";
    },

    login(data) {
        return this.post('/api/user/login', data)
    },
    getHome() {
        return this.get('/api/home');
    },
    getFavorite() {
        return this.get('/api/user/favorite');
    },
    getMe() {
        return this.get('/api/user/me');
    },
    updateMe(data) {
        return this.post('/api/user', data);
    },
    getMyInfo() {
        return this.get('/api/user/info');
    },
    getNews(p, t, o) {
        var url = '/api/news?';
        if (p) {
            url += 'p=' + p;
        } else {
            url += 'p=1';
        }
        if (t) {
            url += '&id=' + t;
        }
        if (o) {
            url += '&o=' + o;
        }
        return this.get(url);
    },
    getNewsDocument(id) {
        return this.get('/api/news/' + id);
    },
    getMenu() {
        return this.get('/api/home/menu');
    },
    getDocumentID(mid) {
        return this.get('/api/document/menu/' + mid);
    },
    getDocument(id) {
        return this.get('/api/document/' + id);
    },
    getLessons() {
        return this.get('/api/lesson');
    },
    getLesson(id) {
        return this.get('/api/lesson/' + id);
    },
    buyLesson(id) {
        return this.post('/api/lesson/buy/' + id);
    },
    visitLesson(id) {
        return this.post('/api/lesson/visit/' + id);
    },
    startLesson(id) {
        return this.post('/api/lesson/start/' + id);
    },
    countdownLesson(id, time) {
        var url = '/api/lesson/countdown/' + id;
        if (time) {
            url += "?time=" + time;
        }
        return this.post(url);
    },
    addFavoriteLesson(id) {
        return this.put('/api/lesson/favorite/' + id);
    },
    removeFavoriteLesson(id) {
        return this.del('/api/lesson/favorite/' + id);
    },
    register(data) {
        return this.post('/api/user/register', data);
    },
    authDocument(id) {
        return this.get('/api/document/auth/' + id);
    },
    forget(data) {
        return this.get('/api/user/forget', data);
    },
    getTalents() {
        return this.get('/api/talent');
    },
    getTalent(id) {
        return this.get('/api/talent/' + id);
    },
    getImages(type, id) {
        return this.get(`/api/image?type=${type}&id=${id}`);
    },
    getPreview(id) {
        return this.get('/api/lesson/preview/' + id);
    },
    startPreview(id) {
        return this.post('/api/lesson/preview/' + id);
    },
    sendForgetPassword(data) {
        return this.post('/api/user/forget', data);
    },
    resetPassword(data) {
        return this.post('/api/user/reset', data);
    },
    submitOrder(data) {
        return this.put('/api/order', data);
    },
    getConfig(key) {
        return this.get('/api/config/' + encodeURIComponent(key));
    },
    sendTalentMail(id, data) {
        return this.post('/api/talent/mail/' + id, data);
    },
    getJournalSummery() {
        return this.get('/api/journal/summery');
    },
    getJournalCategories() {
        return this.get('/api/journal/categories');
    },
    getJournals(data) {
        var url = '/api/journal?_=' + new Date().getTime();
        if (data) {
            for (var key in data) {
                if (data[key]) {
                    url += `&${key}=${encodeURIComponent(data[key])}`;
                }
            }
        }
        return this.get(url);
    },
    getJournal(id) {
        return this.get('/api/journal/' + id);
    },
    visitTalent(id){
        return this.post('/api/talent/visit/' + id);
    },
    downloadJournalLog(id, data) {
        return this.post('/api/journal/download/' + id, data);
    },
    getExam(id){
        return this.get('/api/lesson/exam/' + id);
    },
    sendExam(id, data){
        return this.post('/api/lesson/exam/' + id, data);
    }
}